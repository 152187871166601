body {
  margin: 0;
  background-color: #f7f7f7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root > div > .amplify-flex {
  padding: 20px;
}

/*
#root > div > .amplify-flex > div {
  max-height: none !important; 
  height: auto !important;
  width: auto !important;
  max-width: none !important;
}
*/

#root > div > .amplify-flex > div > .amplify-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100px;
}

.liveness-detector {
  border-radius: 15px;
  border: 1px solid #ececf3;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  background-color: white;
}

.liveness-detector .amplify-alert {
  border-radius: 5px;
}

.liveness-detector .amplify-flex .amplify-liveness-start-screen-header__heading {
  font-size: 28px;
}

.liveness-detector .amplify-flex > * {
  text-align: center;
}

.liveness-detector .amplify-flex.amplify-liveness-figures {
  justify-content: center;
  flex-wrap: nowrap;
}

.liveness-detector .amplify-liveness-instruction-list li, .liveness-detector .amplify-liveness-instruction-list li span {
  text-align: left;
}

.liveness-detector .amplify-liveness-instruction-list {
  display: inline-flex !important;
}

.liveness-detector .amplify-liveness-start-screen-warning + .amplify-flex {
  justify-content: center;
  align-items: center;
}

.swal2-actions .swal2-confirm {
  background-color: var(--amplify-components-button-primary-background-color);
}

.liveness-detector .amplify-liveness-start-screen-warning {
  background-color: #ffe493;
}